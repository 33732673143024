<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8807 8.68555H83.1224C87.2416 8.68555 90.6094 12.0533 90.6094 16.1725V16.3117H9.39373V16.1725C9.3659 12.0533 12.7336 8.68555 16.8807 8.68555ZM90.3032 16.0334C90.2197 12.109 87.019 8.96387 83.0946 8.96387H16.8529C12.9285 8.96387 9.72771 12.1368 9.64422 16.0334H90.3032Z"
      fill="#333333"
    />
    <path
      d="M9.53906 91.3151V16.1889L90.4543 16.1738L90.3633 91.3151H9.53906Z"
      stroke="#333333"
      stroke-width="0.3"
    />
    <circle cx="28.2701" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <circle cx="23.9205" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <circle cx="19.5709" cy="12.3072" r="1.29976" stroke="#333333" stroke-width="0.3" />
    <mask
      id="mask0_922_13333"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="9"
      y="16"
      width="82"
      height="76"
    >
      <rect x="9.47656" y="16.2285" width="80.7373" height="74.9316" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_922_13333)">
      <path
        d="M71.0068 75.5204L79.332 83.8457L81.888 81.2897L73.5628 72.9644L71.0068 75.5204Z"
        fill="white"
      />
      <path
        d="M73.6703 72.8544C73.6014 72.7855 73.4981 72.7855 73.4293 72.8544L70.8817 75.4019C70.8129 75.4708 70.8129 75.5741 70.8817 75.6429L79.2129 83.9741C79.2473 84.0085 79.2818 84.0085 79.3506 84.0085C79.4195 84.0085 79.4539 84.0085 79.4883 83.9741L82.0359 81.4265C82.0703 81.3921 82.0703 81.3577 82.0703 81.2888C82.0703 81.22 82.0359 81.1856 82.0359 81.1511L73.6703 72.8544ZM79.3162 83.5954L71.226 75.5052L73.5326 73.1986L81.6228 81.2888L79.3162 83.5954Z"
        fill="#333333"
      />
      <path
        d="M83.1039 79.5683C82.794 79.2585 82.2777 79.2585 81.9678 79.5683L78.4907 83.0454L77.5957 83.9405C77.2858 84.2503 77.2858 84.7667 77.5957 85.0765L105.515 112.996C105.688 113.168 105.825 113.306 106.032 113.409C107.547 114.477 109.681 114.339 111.024 112.996C112.538 111.482 112.538 109.037 111.024 107.523L83.1039 79.5683Z"
        :fill="primaryColors.primary300"
      />
      <path
        d="M83.2071 79.4657C82.8284 79.087 82.2087 79.087 81.83 79.4657L77.4578 83.8378C77.0792 84.2165 77.0792 84.8362 77.4578 85.2149L105.378 113.135C105.55 113.307 105.722 113.445 105.894 113.582C106.583 114.064 107.374 114.34 108.235 114.34C109.302 114.34 110.335 113.926 111.092 113.169C111.85 112.412 112.263 111.379 112.263 110.312C112.263 109.244 111.85 108.212 111.092 107.454L83.2071 79.4657ZM110.886 112.859C110.197 113.548 109.268 113.961 108.269 113.961C107.478 113.961 106.755 113.72 106.135 113.272C105.963 113.169 105.791 113.031 105.653 112.859L77.7333 84.9394C77.4923 84.6985 77.4923 84.2854 77.7333 84.0444L82.1054 79.6722C82.2087 79.5689 82.3808 79.5001 82.553 79.5001C82.7251 79.5001 82.8628 79.5689 83.0005 79.6722L110.92 107.592C111.609 108.281 112.022 109.21 112.022 110.208C112.022 111.207 111.574 112.171 110.886 112.859Z"
        fill="#333333"
      />
      <path
        d="M70.2297 65.9023L63.9297 72.2024L67.0625 75.3352C68.8182 77.0909 71.6068 77.0909 73.3625 75.3352C75.1183 73.5794 75.1183 70.7909 73.3625 69.0351L70.2297 65.9023Z"
        fill="#6A9FF6"
      />
      <path
        d="M70.2305 65.6621L63.6895 72.2031L66.96 75.4736C67.8206 76.3343 68.9911 76.8163 70.2305 76.8163C71.4698 76.8163 72.6403 76.3343 73.501 75.4736C75.2912 73.6835 75.2912 70.7228 73.501 68.9326L70.2305 65.6621ZM73.26 75.2326C72.4682 76.0245 71.3665 76.472 70.2305 76.472C69.0944 76.472 68.0272 76.0245 67.2009 75.2326L64.1714 72.2031L70.2305 66.1441L73.26 69.1736C74.9469 70.8605 74.9469 73.5802 73.26 75.2326Z"
        fill="#333333"
      />
      <path
        d="M92.3307 97.7786C88.9569 94.818 86.0307 92.2704 85.5487 91.5475C85.4799 91.4098 85.411 91.3409 85.3766 91.2721C85.6176 91.4098 85.9962 91.6852 86.2717 91.8573C87.2356 92.5458 87.5454 92.718 87.752 92.5458C87.9241 92.4081 87.8208 92.1327 87.3733 91.4786C85.4798 88.6901 78.0437 84.0425 77.6995 83.8359L77.5273 84.1114C77.5962 84.1458 85.2389 88.9311 87.0979 91.6507C87.27 91.9261 87.3733 92.0983 87.4422 92.2016C87.2012 92.0639 86.788 91.7884 86.5126 91.5819C85.5831 90.9278 85.2733 90.7212 85.0667 90.8934C84.8946 91.0311 85.0323 91.3065 85.2733 91.7196C85.7553 92.477 88.5782 94.9557 92.1241 98.0541C96.9094 102.254 104.931 109.277 104.38 110.207C104.38 110.241 104.173 110.448 102.831 109.966L102.727 110.275C103.347 110.516 103.829 110.62 104.173 110.62C104.449 110.62 104.621 110.551 104.724 110.379C105.309 109.243 99.9389 104.457 92.3307 97.7786Z"
        fill="#333333"
      />
      <path
        d="M101.041 99.8418C97.6669 97.3975 94.4653 95.0566 93.0882 93.1287C90.8505 89.9959 88.2341 87.9303 86.1685 86.2778C84.516 84.9696 83.2078 83.9368 82.5537 82.8007C82.1062 82.0434 82.0029 81.4581 82.2439 81.045C82.6226 80.3565 83.7931 80.2876 83.7931 80.2876V79.9434C83.7242 79.9434 82.416 80.0122 81.934 80.8729C81.6242 81.4237 81.7275 82.1122 82.2439 82.9729C82.9324 84.1778 84.275 85.2106 85.9275 86.5532C87.9931 88.2057 90.5751 90.2369 92.8128 93.3352C94.2243 95.2975 97.4604 97.6385 100.834 100.117C105.585 103.594 110.99 107.519 111.472 110.721C111.575 111.512 111.403 112.235 110.887 112.889L111.162 113.096C111.713 112.373 111.954 111.547 111.816 110.652C111.334 107.312 106.101 103.525 101.041 99.8418Z"
        fill="#333333"
      />
      <path
        d="M94.0515 90.8931C93.3974 90.1358 92.7433 89.4128 92.1924 88.7931C91.0564 87.5538 89.3006 86.521 87.7859 85.5915C86.3055 84.7308 84.9285 83.9046 84.6186 83.1816C84.5153 82.9751 84.5498 82.9062 84.5498 82.8718C84.7219 82.6997 86.0301 83.2161 87.3039 83.939L87.476 83.6292C86.6842 83.1816 84.7908 82.1488 84.3088 82.5964C84.1367 82.7341 84.1367 82.9751 84.3088 83.2849C84.6875 84.1112 86.0301 84.903 87.6137 85.8669C89.1285 86.762 90.8498 87.7948 91.9515 88.9997C92.5023 89.6194 93.122 90.3079 93.8105 91.0997C95.8761 93.4407 98.4236 96.367 102.038 99.7752C106.135 103.665 108.201 104.974 108.235 104.974L108.407 104.698C108.373 104.698 106.342 103.39 102.279 99.5342C98.6646 96.126 96.0826 93.2341 94.0515 90.8931Z"
        fill="#333333"
      />
      <path
        d="M88.51 93.442C87.9592 92.9944 88.0625 92.8223 88.2346 92.478C88.51 92.0305 88.8543 91.3764 87.5805 90.0682C85.4805 87.8993 78.6641 82.9075 78.5952 82.873L78.3887 83.1485C78.4575 83.1829 85.274 88.1747 87.3395 90.3092C88.4068 91.4108 88.1658 91.8928 87.9248 92.3059C87.6838 92.7534 87.5461 93.1321 88.2691 93.6829C89.6461 94.7502 103.107 106.249 105.207 109.037C107.272 111.757 107.961 113.96 107.995 113.995L108.34 113.891C108.305 113.788 107.617 111.619 105.517 108.831C103.417 106.076 89.8871 94.5436 88.51 93.442Z"
        fill="#333333"
      />
      <path
        d="M50.0202 27.582C36.4906 27.582 25.543 38.5296 25.543 52.0592C25.543 65.5888 36.4906 76.5364 50.0202 76.5364C63.5497 76.5364 74.4973 65.5888 74.4973 52.0592C74.4973 38.5296 63.5153 27.582 50.0202 27.582ZM50.0202 72.6462C38.6594 72.6462 29.4332 63.4199 29.4332 52.0592C29.4332 40.6985 38.6594 31.4722 50.0202 31.4722C61.3809 31.4722 70.6072 40.6985 70.6072 52.0592C70.6072 63.4199 61.3809 72.6462 50.0202 72.6462Z"
        :fill="primaryColors.primary100"
      />
      <path
        d="M50.0208 31.2988C38.5913 31.2988 29.2617 40.594 29.2617 52.0579C29.2617 63.4875 38.5568 72.8171 50.0208 72.8171C61.4504 72.8171 70.78 63.5219 70.78 52.0579C70.78 40.594 61.4504 31.2988 50.0208 31.2988ZM50.0208 72.4728C38.7634 72.4728 29.606 63.3154 29.606 52.0579C29.606 40.8005 38.7634 31.6431 50.0208 31.6431C61.2783 31.6431 70.4357 40.8005 70.4357 52.0579C70.4357 63.3154 61.2783 72.4728 50.0208 72.4728Z"
        fill="#333333"
      />
      <path
        d="M50.0204 27.4082C36.422 27.4082 25.3711 38.4591 25.3711 52.0575C25.3711 65.6559 36.422 76.7068 50.0204 76.7068C63.6188 76.7068 74.6697 65.6559 74.6697 52.0575C74.6697 38.4591 63.5844 27.4082 50.0204 27.4082ZM50.0204 76.3281C36.6285 76.3281 25.7154 65.415 25.7154 52.0231C25.7154 38.6312 36.6285 27.718 50.0204 27.718C63.4123 27.718 74.3255 38.6312 74.3255 52.0231C74.3255 65.4494 63.4123 76.3281 50.0204 76.3281Z"
        fill="#333333"
      />
      <path
        d="M50.0206 28.6133C37.1107 28.6133 26.5762 39.1133 26.5762 52.0577C26.5762 64.9676 37.0762 75.5021 50.0206 75.5021C62.9649 75.5021 73.4649 65.002 73.4649 52.0577C73.4649 39.1133 62.9305 28.6133 50.0206 28.6133ZM50.0206 75.1234C37.2828 75.1234 26.9204 64.761 26.9204 52.0232C26.9204 39.2855 37.2828 28.9231 50.0206 28.9231C62.7583 28.9231 73.1207 39.2855 73.1207 52.0232C73.1207 64.761 62.7583 75.1234 50.0206 75.1234Z"
        fill="#333333"
      />
      <path
        d="M50.037 46.2322C51.121 46.2322 51.9998 45.3535 51.9998 44.2694C51.9998 43.1854 51.121 42.3066 50.037 42.3066C48.953 42.3066 48.0742 43.1854 48.0742 44.2694C48.0742 45.3535 48.953 46.2322 50.037 46.2322Z"
        fill="white"
      />
      <path
        d="M50.0382 42.1172C48.8304 42.1172 47.8867 43.0986 47.8867 44.2687C47.8867 45.4388 48.8681 46.4202 50.0382 46.4202C51.2083 46.4202 52.1897 45.4388 52.1897 44.2687C52.1897 43.0986 51.2083 42.1172 50.0382 42.1172ZM50.0382 46.0428C49.0568 46.0428 48.2642 45.2501 48.2642 44.2687C48.2642 43.2873 49.0568 42.4946 50.0382 42.4946C51.0196 42.4946 51.8123 43.2873 51.8123 44.2687C51.8123 45.2501 51.0196 46.0428 50.0382 46.0428Z"
        fill="#333333"
      />
      <path
        d="M50.0372 61.9298C51.83 61.9298 53.2833 60.4764 53.2833 58.6836C53.2833 56.8908 51.83 55.4375 50.0372 55.4375C48.2444 55.4375 46.791 56.8908 46.791 58.6836C46.791 60.4764 48.2444 61.9298 50.0372 61.9298Z"
        fill="white"
      />
      <path
        d="M50.0384 55.248C48.1511 55.248 46.6035 56.7956 46.6035 58.6829C46.6035 60.5702 48.1511 62.1178 50.0384 62.1178C51.9257 62.1178 53.4733 60.5702 53.4733 58.6829C53.4733 56.7956 51.9257 55.248 50.0384 55.248ZM50.0384 61.7404C48.3398 61.7404 46.981 60.3815 46.981 58.6829C46.981 56.9844 48.3398 55.6255 50.0384 55.6255C51.737 55.6255 53.0958 56.9844 53.0958 58.6829C53.0958 60.3815 51.737 61.7404 50.0384 61.7404Z"
        fill="#333333"
      />
      <path
        d="M58.1172 56.0775C57.9663 55.7756 57.6643 55.6246 57.3246 55.6246C56.8339 55.6246 56.4187 55.2094 56.4187 54.7187V50.6421C56.4187 47.1317 53.5877 44.3008 50.0773 44.3008C46.567 44.3008 43.736 47.1317 43.736 50.6421V54.7187C43.736 55.2094 43.3208 55.6246 42.8301 55.6246C42.4904 55.6246 42.1884 55.8133 42.0374 56.0775L41.2825 57.3609C40.9428 57.9648 41.358 58.7198 42.0752 58.7198H58.0795C58.7967 58.7198 59.2119 57.9648 58.8722 57.3609L58.1172 56.0775Z"
        fill="white"
      />
      <path
        d="M58.2678 56.0013C58.0791 55.6616 57.7016 55.4351 57.2864 55.4351C56.8712 55.4351 56.5692 55.0954 56.5692 54.7179V50.6414C56.5692 47.0555 53.625 44.1113 50.0392 44.1113C46.4533 44.1113 43.5091 47.0555 43.5091 50.6414V54.7179C43.5091 55.1332 43.1694 55.4351 42.7919 55.4351C42.3767 55.4351 42.037 55.6616 41.8105 56.0013L41.0556 57.2847C40.8669 57.6244 40.8669 58.0396 41.0556 58.3793C41.2443 58.719 41.6218 58.9455 41.9993 58.9455H58.0036C58.4188 58.9455 58.7585 58.7568 58.9472 58.3793C59.136 58.0396 59.136 57.6244 58.9472 57.2847L58.2678 56.0013ZM58.683 58.1906C58.532 58.4171 58.3055 58.568 58.0413 58.568H42.037C41.7728 58.568 41.5463 58.4171 41.3953 58.1906C41.2443 57.9641 41.2443 57.6999 41.3953 57.4734L42.1503 56.19C42.2635 55.9636 42.5277 55.8126 42.7919 55.8126C43.3959 55.8126 43.8866 55.3219 43.8866 54.7179V50.6414C43.8866 47.2442 46.642 44.4888 50.0392 44.4888C53.4363 44.4888 56.1918 47.2442 56.1918 50.6414V54.7179C56.1918 55.3219 56.6825 55.8126 57.2864 55.8126C57.5506 55.8126 57.7771 55.9636 57.9281 56.19L58.683 57.4734C58.7962 57.6999 58.7962 57.9641 58.683 58.1906Z"
        fill="#333333"
      />
      <path
        d="M58.1562 56.1953H41.8877L41.2083 57.3654C40.8686 57.9694 41.2838 58.7243 42.001 58.7243H58.0053C58.7225 58.7243 59.1377 57.9694 58.7979 57.3654L58.1562 56.1953Z"
        fill="white"
      />
      <path
        d="M58.3426 56.1191C58.3049 56.0436 58.2294 56.0059 58.1917 56.0059H41.9231C41.8476 56.0059 41.8099 56.0436 41.7721 56.1191L41.0927 57.2892C40.904 57.6289 40.904 58.0441 41.0927 58.3839C41.2814 58.7236 41.6589 58.95 42.0364 58.95H58.0407C58.4559 58.95 58.7956 58.7613 58.9843 58.3839C59.173 58.0064 59.173 57.6289 58.9843 57.2892L58.3426 56.1191ZM58.6823 58.1951C58.5314 58.4216 58.3049 58.5726 58.0407 58.5726H42.0364C41.7721 58.5726 41.5457 58.4216 41.3947 58.1951C41.2437 57.9686 41.2437 57.7044 41.3947 57.478L41.9986 56.3833H58.0407L58.6446 57.478C58.7956 57.7044 58.7956 57.9686 58.6823 58.1951Z"
        fill="#333333"
      />
    </g>
  </svg>
</template>

<script setup lang="ts">
const primaryColors = computed(() => {
  return getPrimaryColors();
});
</script>
